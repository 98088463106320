import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/global/layout"
import PostGrid from "../components/global/PostGrid"
import SearchEngineOptimizaion from "../components/global/SearchEngineOptimizaion"
import Filter from "../components/pages/ingredients/Filter"

export default function Ingredients({ data }) {
  const { categories, ingredients } = data

  return (
    <Layout>
      <SearchEngineOptimizaion
        title={"Ingrediens oversigt"}
        description={`Her finder du en oversigt over ingredienser og råvarer - samt hvornår disse råvarer og ingredienser er i sæson.`}
      />
      <div className="ingredientGrid">
        <div className="ingredientsHeader">
          <h1>Alle Ingredienser</h1>
        </div>
        <Filter categories={categories} ingredients={ingredients} />
        <div className="postGridWrapper">
          <PostGrid posts={ingredients.nodes} slugPrefix={"ingrediens"} />
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query ingredientsQuery {
    categories: allSanityIngredientCategories {
      nodes {
        title
        slug {
          current
        }
      }
    }
    ingredients: allSanityIngredients(sort: { title: ASC }) {
      nodes {
        title
        slug {
          current
        }
        image {
          asset {
            gatsbyImageData(
              width: 300
              backgroundColor: "#f9f9f9"
              placeholder: NONE
            )
          }
        }
      }
    }
  }
`
